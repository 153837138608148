@import '../../styles/variables.css';

.lightStyle {
  .title,
  .value {
    color: var(--white);
  }

  .titleValue,
  .item {
    color: color(#fff a(70%));
  }
}

.title {
  margin: 0 0 20px;
  @mixin font-bolder;
  font-size: 12px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;
  color: #3c3b3b;
}

.titleValue {
  display: block;
  text-align: center;
  color: var(--white);

  @media (--tablet-landscape-up) {
    color: #3c3b3b;
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  max-width: 300px;
}

.item {
  margin-right: 20px;
  @mixin font-family-title;
  @mixin font-thin;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: var(--black);
}

.itemMetric,
.itemImperial {
  .titleValue {
    opacity: 0.35;
    letter-spacing: 0.43px;
    @mixin font-bolder;
    transition: opacity 0.2s;

    @media (--tablet-landscape-up) {
      color: var(--black);
    }

    &:hover {
      opacity: 1;
    }
  }
}

.itemMetric {
  margin-right: 5px;
}

.itemImperial {
  position: relative;
  margin-right: 27px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: -15px;
    transform: translateY(-50%);
    width: 6px;
    height: 1px;
    background-color: var(--black);
  }
}

.itemActive .titleValue {
  opacity: 1;
}

.itemNewLine {
  break-before: always;
}

.toggleWrapMobile {
  display: block;

  @media (--tablet-landscape-up) {
    display: none;
  }

  .item {
    width: auto;
    margin-right: 10px;
    margin-bottom: 0;

    &::before {
      content: none;
    }

    .titleValue {
      color: var(--black);
    }
  }
}

.toggle {
  @mixin btn-reset;
  @mixin font-bolder;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  text-transform: uppercase;
  color: var(--white);

  @media (--tablet-landscape-up) {
    color: var(--black);
  }
}

.value {
  opacity: 0.51;
  @mixin font-family-title;
  margin-left: 3px;
  text-align: center;
  color: var(--white);

  @media (--tablet-landscape-up) {
    color: var(--black);
  }
}

.shoeSizesWrapper {
  position: relative;
  flex-shrink: 0;
  width: 55px;

  select {
    width: 100%;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 3px;
    right: -12px;
    transform: rotate(-90deg);
    width: 10px;
    height: 10px;
    background-image: var(--cursor-back-icon-black);
    background-repeat: no-repeat;
    z-index: var(--z-behind);
  }
}
