@import '../../styles/variables.css';

.container {
  img {
    opacity: 0;
    transition: opacity 0.3s;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #f2f2f2;
      z-index: var(--z-default);
    }
  }
}

.loaded img {
  opacity: 1;
}
