@import '../../styles/variables.css';

.container {
  position: absolute;
  bottom: 9px;
  left: 10px;
  display: flex;
  align-items: center;
}

.content {
  position: absolute;
  left: 0;
  bottom: 100%;
  min-width: 290px;
  margin-bottom: 10px;
  background: #fff;
  padding: 30px;
  border: 2px solid #000;
}

.error {
  color: red;
  margin-bottom: 30px;
  font-size: 14px;
}

.collections {
  margin-top: 15px;
}

.collection {
  display: flex;
  align-items: center;
  font-size: 14px;

  &::before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 6px solid #000;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    margin-right: 5px;
  }
}

.button {
  padding: 7px 12px;
  font-size: 12px;
  border: 1px solid #000;
  border-radius: 0;
  background: #fff;
  outline: none;
  cursor: pointer;
  z-index: var(--z-secondary);
}

.smallButton {
  font-size: 12px;
  padding: 4px 8px;
  margin-left: 15px;
}

.button:not(:last-child) {
  margin-right: 12px;
}

.input {
  border: 1px solid #000;
  padding: 7px 12px;
  width: 100%;
}
