@import '../../../styles/variables.css';

.container {
  display: block;
  position: relative;
  margin-bottom: 20px;

  @media (--tablet-landscape-up) {
    margin-bottom: 42px;
  }

  &:hover {
    @media (--tablet-landscape-up) {
      .overlay {
        opacity: 1;
      }
    }
  }
}

.imgWrap {
  overflow: hidden;
  position: relative;
  margin-bottom: 19px;
  background-color: #f6f6f6;

  &::before {
    content: '';
    display: inline-block;
    width: 1px;
    height: 0;
    padding-bottom: calc(4 / 3 * 100%);
  }
}

.img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  img {
    @mixin fill-image;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.overlay {
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10%;
  transition: opacity 0.3s;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

body .measurements {
  display: flex;
  align-items: center;
}

body .measurementsList {
  justify-content: center;
}

body .measurement,
body .measurementValue {
  color: var(--white);
}

body .measurement {
  &:not(:last-child) {
    margin: 0 var(--space-xs) var(--space-xs) var(--space-xs);
  }
}

.name {
  display: block;
  margin-bottom: 2px;
  padding: 0 16px;
  @mixin font-family-title;
  @mixin font-boldest;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.666667px;
  text-transform: uppercase;
  color: var(--black);
}

.nameNoPadding {
  padding: 0;
}

.instagram {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: var(--space-md);
  @mixin font-family-title;
  font-size: 14px;
  color: var(--white);
  z-index: var(--z-default);

  @media (--tablet-landscape-up) {
    margin-top: var(--space-lg);
  }
}

.instagramGrid {
  opacity: 0;
  padding-left: 16px;
  color: var(--black);
  transition: opacity 0.2s;

  @media (--tablet-landscape-up) {
    position: absolute;
    left: 16px;
    bottom: -35px;
    padding-left: 0;
  }

  .instagramIcon {
    fill: var(--black);
  }
}

.instagramVisible {
  opacity: 1;
}

.instagramIcon {
  display: block;
  width: 20px;
  height: 20px;
  margin-right: var(--space-xs);
  fill: var(--white);
}
